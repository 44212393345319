/* The sidebar menu */
.sidebar {
    height: 100%; /* 100% Full-height */
    width: 40px; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    /* top: 132px; */
    left: 0;
    opacity: 1.0;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 0px; /* Place content 60px from the top */
    /* transition: 0.15s; */ /* 0.5 second transition effect to slide in the sidebar */
    white-space: nowrap;
    border-right-style: inset;
    background-color: var(--sb-color);
    color: var(--sbicon-color);
    top: 66px;
}

@media (max-width: 800px) {
    .sidebar {
        /* top: 76px; */
        display: none;
    }
}

.sidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: var(--sbicon-color);
    display: block;
    transition: 0.15s;
}

/*
.sidebar {
    height: 100%;
    width: 40px;
    position: fixed;
    z-index: 1;

    left: 0;
    opacity: 1.0;
    overflow-x: hidden;
    padding-top: 0px;

    white-space: nowrap;
    border-right-style: inset;
    background-color: #62174C;
    color: lightgrey;
    top: 66px;
}
*/
.sidebaritem1 {
    padding: 8px;
    cursor: pointer;
}

/* The sidebar links */
/*
.sidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.15s;
}
*/
/* When you mouse over the navigation links, change their color */
.sidebar a:hover {
    color: ghostwhite;
}

.sidebaritem {
    margin-top: 20px;
}

.sidebaritem:hover
{
    background: #0594a9;
    color: ghostwhite;
    cursor: pointer;
}

.sidebaricon{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    paddign-right: 10px;
    font-size: large;
    width: 20px;
    z-index: 100;
    cursor: pointer;
}
.sidebaricon:hover{
    color: ghostwhite;
}


.sidebarlabel{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    z-index: 100;
    color: ghostwhite;
    font-size: medium;
    display: inline;
}

/* The button used to open the sidebar */
.openbtn {
    cursor: pointer;
    background-color: #222222;
    color: grey;
    border: none;
    padding-bottom: 20px;
}

.openbtn:hover {
    background-color: #444;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
.maincontent {
    transition: margin-left .5s; /* If you want a transition effect */
    margin-left: 60px;
    margin-top: 10px;
    width: 95%;
}

@media (max-width: 800px) {
    .maincontent {
        margin-top: 20px;
        margin-left: 10px;
    }
}


.togglesidenav {
    z-index: 200;
    float: right;
    position: absolute;
    margin-left: 30px;
    margin-top: -25px;
    width: 100%;
    cursor: pointer;
}

.togglesidenav:hover{
    background:darkslateblue;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-width: 617px) {
    .sidebar {
        padding-top: 35px;
    }
    .maincontent {
        margin-left: 20px;

    }
    .sidebar a {font-size: 14pt;}
    .sidebaricon {font-size: small;}
}