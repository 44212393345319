.topnav {
    width: 100%;
    overflow: hidden;
    background-color: transparent;
    z-index: 1;
    text-align: center;
    vertical-align: middle;
}

.topnav a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
}

.topnav a:hover {
    background-color: #ddd;
    color: black;
}

.topnav a.active {
    background-color: #4CAF50;
    color: white;
}

.topnav .icon {
    display: none;
}


.welcomemsg {
    float: right;
    font-size: .8rem;
    letter-spacing: 2px;
}

.mynav {
    background-color: var(--mn-color);
    height: 65px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 100;
}

.mynav .n-widgettools {
    background-color: var(--mn-color);
    color: var(--hdtext-color);
    margin-right: 5px;

}

@media (max-width: 1400px) {
    .mynav .n-widgettools {
        padding-top: 0px;
        margin-top: 3px;
        font-size: 0.5rem;
    }
}

.mynav .n-widgettools i {
    padding: 3px;
    padding-left: 5px;
    padding-right: 5px;
}

.tnlink {
    font-size: 1rem;

}


.navsearch {
    font-size: 0.8rem;
    color: var(--hdtext-color);
    padding-left: 0px;
    padding-right: 0px;
}
