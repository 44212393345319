.oaflex-container {
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    width: 100%;
    align-items: stretch;
}

@media (max-width: 950px) {
    .oaflex-container {
        left: 5px;
    }
}

.odash-card {
    margin: 5px;
    padding: 5px;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    border-color: slategrey;
    max-height: 300px;
    min-height: 130px;

    overflow-y: auto;
    overflow-x: hidden;
    background-color: white;
    max-width: 350px;
    display: inline-block;
    height: auto;

}

@media (max-width: 950px) {
    .odash-card {
        padding: 3px;
        margin: 3px;
        width: 98%;
        max-width: 98%;
        min-height: 80px;
    }
}

.odash-content {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    max-height: 190px;
}

.odash-name {
    position: relative;
    top: 0px;
    left: 0px;
    padding: 2px;
    text-align: left;
    border-bottom-style: solid;
    border-bottom-width: thin;
    border-bottom-color: darkslateblue;
    font-size: 1.2rem;
    width: 100%;
}

.odash-task {
    clear: both;
    display: block;
    width: 95%;
    margin: 5px 5px 5px 10px;
    font-size: .8rem;
}

.odash-task-item {
    font-size: .8rem;
    width: 95%;
    display: block;
    margin-top: 5px;
    border-top-style: solid;
    border-top-width: thin;
    border-top-color: cornflowerblue;
}

.odash-taskassignment {
    font-size: .7rem;
}


.odash-tasklabel {
    position: relative;
    left: 0px;
    padding: 5px;
    padding-left: 10px;
    font-size: x-small;
    color: cornflowerblue;
    width: 95%;
}

.odash-taskname {
    display: block;
}

.odash-toolbar {
    width: 100%;
    clear: both;
    display: block;
    flex-flow: row nowrap;
    align-items: right;
    text-align: right;
    top: 10px;
    border-bottom-style: solid;
    border-bottom-width: thin;
    border-bottom-color: darkslateblue;
}

.odash-toolbar .ouido-icon {
    flex-grow: 1;
    flex-basis: auto;
    align-self: stretch;
    padding: 3px 8px;
    font-size: 1.1rem;
}


.odash-card .odash-indicators {
    padding: 3px 8px;
    font-size: 1rem;
}

@media (max-width: 950) {
    padding:

8px

10px

;
    font-size:

2rem

;
}

.odash-card .btn-sm {
    padding: 0.1rem 0.35rem !important;
    line-height: 1.5 !important;

}

/*
.btn {
    background-color: darkslategray !important;;
    color: darkgrey !important;
    font-size: .8rem;
}

.btn-warning {
    background-color: red !important;;
    color: white !important;
    font-size: .8rem;
}



.btn-success {
    background-color: limegreen !important;;
    color: black !important;
    font-size: .8rem;
}
*/

.archivedDeal {
    background-color: #999999;
}
