.n-date-row {
    font-size: 0.8rem;
}

.dealcard {
    margin: 3px;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    border-color: silver;
    min-height: 7vh;
    /*max-height: 20vh;*/
    overflow-y: auto;
    overflow-x: hidden;
}

.dealcard-content {
    position: relative;
    /* max-height: 80%; */
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 5px;
}

.dealcard-name {
    display: inline;
    position: relative;
    top: 0;
    left: 0;

    padding: 0px 10px 10px 10px;
    text-align: left;

    border-bottom-style: none;
    border-bottom-width: thin;
    border-bottom-color: linen;

    font-size: 0.9rem;
    color: #330036;
}

dealcard-note {
    display: inline;
    position: relative;
    top: 0;
    left: 0;

    padding: 0px 10px 10px 10px;
    text-align: left;

    font-size: 0.9rem;
    color: slategrey;
}

.dealcard-toolbar {
    float: right;
    text-align: right;
    display: block;
    width: 100%;
    font-size: small;
    border-width: thin;
    border-bottom-style: none;
}

.dealcard-task {
    clear: both;
    display: block;
    width: 100%;
    margin: 5px;
    font-size: 0.9rem;
    color: slategrey;
    vertical-align: middle;
}

.dealcard-task-item {
    width: 100%;
    display: block;
}

.dealcard-date {
    display: inline;
    font-size: 1.0rem;
    font-weight: 500;
}

.dealcard-reason {
    font-size: 0.9rem;
    font-style: italic;
    font-color: #4A235A;
}

.dealcard-header {
    display: block;
    border-top-left-radius: 3px;
    -webkit-border-top-left-radius: 3px;
    -moz-border-radius-topleft: 3px;
    border-top-right-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    -moz-border-radius-topright: 3px;
    border-bottom-style: inset;

    background-color: ghostwhite;
    opacity: 1;
    top: 0px;
    left: 0px;

    height: 20px;
    min-height: 20px;
    text-align: center;
    font-size: 0.7rem;
    color: silver;
}

.todaytasks {
    background-color: var(--today-color);
    color: var(--todaytext-color);
}

.overduetasks {
    background-color: var(--overdue-color);
    color: var(--overduetext-color);
}

.datecard {

    margin: 3px;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    border-color: silver;
    min-height: 5vh;
    /*max-height: 20vh;*/
    overflow-y: auto;
    overflow-x: hidden;
}

.datecard-content {

}

.datecard-date {
    display: inline;
    font-size: 1.2rem;
    font-weight: 500;
}

.datecard-name {
    display: inline;
    position: relative;
    top: 0;
    left: 0;

    padding: 0px 10px 10px 10px;
    text-align: left;

    border-bottom-style: none;
    border-bottom-width: thin;
    border-bottom-color: linen;

    font-size: 0.9rem;
    color: #8e678e;
}

.datecard-toolbar {
    text-align: right;
    display: block;
    width: 100%;
    font-size: small;
}

.taskcard {
    margin: 3px;
    padding: 5px;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    border-color: silver;
    min-height: 5vh;
    /*max-height: 20vh;*/
    overflow-y: auto;
    overflow-x: hidden;
}

.taskcard-content {
    max-height: 80%;
    overflow-y: inherit;
    overflow-x: hidden;
}

.taskcard-name {
    display: inline;
    position: relative;
    top: 0;
    left: 0;

    padding: 0px 10px 10px 10px;
    text-align: left;

    border-bottom-style: none;
    border-bottom-width: thin;
    border-bottom-color: linen;

    font-size: 0.9rem;
    color: #8e678e;
}

.taskcard-workflow {
    font-size: 0.8rem;
    margin-left: 35px;
}

.mobilepanel .taskcard-name {
    padding: 0px 5px 5px 5px;
}

.taskcard-toolbar {
    text-align: right;
    display: block;
    width: 75%;
    font-size: small;
    border-width: thin;
    border-bottom-style: none;
}

.tasklabel {
    display: block;
    margin-right: 5px;
    font-size: 0.9rem;
    color: #4A235A;
}

.tasklabel .inline {
    display: inline;
}

.taskfield {
    display: block;
    margin: 5px;
    font-size: 0.9rem;
    color: black;
}

.taskfield .inline {
    display: inline;
}

.tasktags {
    margin-left: 30px;
}

.closing {
    border-bottom-style: solid;
    border-bottom-color: antiquewhite;
    border-bottom-width: 1px;
}

.showlong {
    cursor: pointer;
}

.showshort {
    cursor: pointer;
}

.comptask {
    margin-left: 40px;
}

.comptasklabel {
    font-size: 0.9rem;
    font-style: italic;
    color: #8e678e;
    margin: 5px;
}

.comptaskfield {
    font-size: 1.1rem;
    color: forestgreen;
    margin: 5px;
}


.sortbar {
    position: relative;
    display: inline-block;
    overflow: hidden;
    background-color: var(--sortbar-color);
    color: #5a4567;
    font-size: .8rem;
    float: left;
    width: 100%;
    text-align: center;
    align-items: center;
    height: 28px;
    padding-top: 3px;
}

@media (max-width: 950px;) {
    .sortbar {
        display: none;
    }
}


.sortoption {
    height: 22px;
    color: ghostwhite;
    border-style: solid;
    border-width: 1px;
    border-color: dimgrey;
    display: inline;
    text-align: center;
    margin: 3px;
    padding: 3px;
    cursor: pointer;
}

.sorted {
    background-color: var(--sorted-color);
    color: var(--sortedtext-color);

}

.sorticon {
    margin: 4px;
}

.tbar-vertical {
    float: right;
    text-align: right;
    width: 15px;
    margin-right: 3px;
}

.hiddenTemplate {
    display: none;
}

.hidetemplatebtn {
    font-size: small;
    position: relative;
    top: -10px;
    right: -2px;
    color: lightgrey;
}

.dealstatus {
    display: inline;
    float: right;
    font-size: 1rem;
    color: #4A235A;
}

.waitdate {
    font-size: 0.9rem;
    font-style: italic;
    color: cornflowerblue;
}

.contactcard {
    display: inline-block;
    margin: 5px;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    border-color: silver;
    height: 22vh;

    width: 400px;
    max-width: 400px;
    /*max-height: 20vh;*/
    background-color: ghostwhite;
}

.contactcard-content {
    /* max-height: 80%; */
    max-height: 18vh;
    margin: 5px 5px 5px 15px;
    padding: 5px;
    background-color: ghostwhite;
    font-size: 0.9rem;
    margin-bottom: 20px;
    overflow-x: auto;

}

.contactcard-name {
    display: inline;
    top: 0;
    left: 0;

    padding: 0px 10px 10px 10px;
    text-align: left;

    font-size: 0.9rem;
    color: #330036;
    background-color: ghostwhite;
}

.contactcard-address {
    display: inline;
    position: relative;
    top: 0;
    left: 0;
    padding: 0px 10px 10px 10px;
    text-align: left;

    font-size: 0.9rem;
    color: #330036;
    background-color: ghostwhite;
}

.contactcard-toolbar {
    font-size: 0.9rem;
    width: 5%;
    float: right;
    margin-right: 5px;
    margin-top: 8px;

}

.contactcard-data {
    float: left;
    width: 90%;
}
