.ouido-icon {
    color: #02568C;
    margin-left: 3px;
    margin-right: 3px;
   /* padding: 3px; */
    cursor: pointer;
}
.ouido-icon-light {
    color: ghostwhite;
    cursor: pointer;
}
.ouido-icon-dark {
    color: darkslateblue;
    cursor: pointer;
}

.ouido-icon-red {
    color: indianred;
    /*
    margin-left: 3px;
    margin-right: 3px;
    padding: 3px; */
    cursor: pointer;
}

.ouido-icon-grey {
    color: lightgrey;
    margin-left: 3px;
    margin-right: 3px;
    /* padding: 3px; */
    cursor: pointer;
}

.ouido-redback{
    background-color: darkred;
    margin-left: 3px;
    margin-right: 3px;
    /* padding: 3px; */

}

.ouido-yellowback{
    background-color: yellow;
    opacity: .60;
    margin-left: 3px;
    margin-right: 3px;
    /* padding: 3px; */

}

.ouido-done {
    background-color: ghostwhite;
    color: forestgreen;
}

.overdue {
    background-color: var(--overdue-color);
}

.dot {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
}


.setcomplete {
    height: 1.3rem;
    width: 1.3rem;
    border-radius: 50%;
    display: inline-block;

}

.scheduleddeal {
    display: inline-block;
    border-color: darkblue;
    border-width: 1px;
    border-style: solid;
    background-color: cornflowerblue;
    color: white;
    width: 12px;
    text-align: center;
    font-size: .6rem;
    font-weight: 500;
}