body {
    background-color: var(--bg-color);
    min-height: 720px;
    min-width: 380px;
    font-family: "Open Sans", Roboto, sans-serif;
}
.ouido-body {
    background-color: var(--bg-color);
    min-height: 720px;
    min-width: 380px;
    font-family: "Open Sans", Roboto, sans-serif;
}

@media (max-width: 950px) {
    body {
        font-size: small;
    }
}

h1, h2, h3, h4, h5, h6 {
    color: var(#333333);
    font-family: "Open Sans", Roboto, sans-serif;
}

.ouido-btn {
    background-color: var(--btn-color);
    color: var(--btn-text);
}

.ouido-outline-btn {
    border-color: var(--btn-color);
    background-color: transparent;
    color: var(--btn-color);
}

.page-content-nf {
    position: relative;
    top: 80px;
    overflow-y: auto;
    overflow-x: hidden;
}

.page-content {
    position: relative;
    top: 110px;
    overflow-y: auto;
    overflow-x: hidden;
}

@media (max-width: 1250px) {
    .page-content {
        font-size: small;
        top: 135px;
        margin-left: 0;
        padding-left: 15px;
        padding-right: 0;
        margin-right: 0;
    }
    .maincontent{
        padding-left: 0;
        margin-left: 0;
        padding-right: 0;
        margin-right: 0;
    }


}


.pagetitle {
    display: inline-block;
    font-family: "Open Sans", Roboto, sans-serif;
    font-size: 1.8rem;
    font-weight: 400;
    color: var(--hdtext-color);;
    margin-left: 10px;
    margin-top: 0px;
    padding-top: 5px;
    max-width: 50%;
}


@media (max-width: 950px) {
    .pagetitle {
        overflow-x: hidden;
        max-width: 90%;
        white-space: nowrap;
        /* min-width: 700px; */
        font-size: 1.2rem;
        padding-top: 8px;
    }
}

@media (max-width: 450px) {
    .pagetitle {
        overflow-x: hidden;
        max-width: 50%;
        white-space: nowrap;
        /* min-width: 700px; */
        font-size: 1.2rem;
    }
}


.pagetitle .logo {
    padding-top: 3px;
}


.btn {
    font-family: "Open Sans", Roboto, sans-serif !important;
}



div-line {
    width: 100%;
    height: 1px;
    margin-top: 2px;
    opacity: .4;
    margin-bottom: 2px;
    display: block;
    background-color: cornflowerblue;
}

.alert {
    width: 100%;
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    /* background-image: linear-gradient(white, lightskyblue); *//* Standard syntax (must be last) */
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #333;
    background-color: var(--mn-color);
    text-align: center;
    vert-align: bottom;
    height: 30px;
    font-size: small;
    font-weight: bold;

}

.footer-content {
    color: var(--hdtext-color);
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;

}

.footer-spacer {
    width: 100%;
    clear: both;
    height: 35px;
}


.body-content {
    position:relative;
    top:100px;
    margin-left:50px;
    margin-right:50px;
}


.pagelogo{
    height: 40px;
    margin-top: 4px;
}

@media (max-width: 950px) {
    .pagelogo {
        height: 40px;
    }
}


.nav-main {
    height: 80%;
    width: 40px;
    color: ghostwhite;
    text-align: center;
    vertical-align: middle;
    background-color: var(--mn-color);
    border-style: solid;
    border-width: 1px;
    border-color: grey;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    margin: 2px;
    margin-top: 4px;
    z-index: 10;

}


.nav-main:hover{
    color: #0594a9;
    background-color: ghostwhite;
}

    /* sizing for small screens */
@media (max-width: 950px) {
        .nav-main{
            border-radius: 4px;
            -moz-border-radius: 4px;
            -webkit-border-radius: 4px;
            margin-left: 2px;
            margin-right: 2px;
            padding: 3px;
            width: 25px;
        }
    }

.nav-container {
    /*background-image: linear-gradient(lightskyblue, white);*/    /* Standard syntax (must be last)*/
    /*
    position: fixed;
    left: 0px;
    top: 0px;
    width:100%;
    padding: 10px;*/
    background-color: aliceblue;
    border-bottom-style: inset;
    z-index: 1;
}

.nav-label {
    text-align: center;
    font-size: x-small;
    font-weight: bold;
    color: black;
}

.brand-wrapper {

}

.navbar-brand {
    position:absolute;
    top: 10px;
    left: 10px;
}


.center {
    margin: auto;
    text-align:center;
    padding: 10px;
}

.centerscreen{
    position: fixed;
    top: 35%;
    left: 45%;
    /* bring your own prefixes */
    transform: translate(-35%, -45%);
}


.taskdot {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border-style: solid;
    border-width: thin;
    border-color: darkslategrey;
    display: inline-block;
    text-align: center;
    margin-right: 3px;
    margin-top: 5px;
    background-color: ghostwhite;
}

.overdue {
    background-color: var(--overdue-color) !important;

}

.today {
    background-color: var(--today-color) !important;
}

.userdot {
    height: 10px;
    width: 10px;
    border-radius: 25%;
    border-style: outset;
    border-width: thin;
    display: inline-block;
    text-align: center;
    margin-right: 3px;
    -ms-transform: rotate(45deg); /* IE 9 */
    transform: rotate(45deg);
}

.userdot .userinitials {
    font-size: 6pt;
    color: black;
    padding-top: 1px;
    -ms-transform: rotate(-45deg); /* IE 9 */
    transform: rotate(-45deg);

}

.userdot-lg {
    height: 20px;
    width: 20px;
    border-radius: 20%;
    border-style: outset;
    border-width: thin;
    display: inline-block;
    margin-right: 5px;
    text-align: center;
    padding: 2px;
    -ms-transform: rotate(45deg); /* IE 9 */
    transform: rotate(45deg);
}

.userdot-lg .userinitials {
    font-size: 6pt;
    color: black;
    padding-top: 1px;
    -ms-transform: rotate(-45deg); /* IE 9 */
    transform: rotate(-45deg);

}

.paneleditor {
    position: fixed;
    top: 65px;
    right: 0;
    background: var(--pe-color);
    overflow: auto;
    padding: 75px 10px 10px 10px;
    max-width: 75vw;
    height: 100vh;
    width: 75vw;
    z-index: 200;
    transition: .3s ease-out;
}

.largepanel {
    width: 97vw;
    max-width: 97vw;
    transition: .3s ease-out;
}

.paneleditor .n-widget {
    max-height: 85%;
    overflow-y: auto;
}

@media (max-width: 1100px) {
    .paneleditor {
        font-size: medium;
        width: 75vw;
        max-width: 75vw;
    }
}

@media (max-width: 950px) {
    .paneleditor {
        font-size: small;
        width: 100vw;
        max-width: 100vw;
    }
}

.selectedfilter {
    color: red;
}

.unreadmsgs {
    color: red;
}


.mobiledashbuttons {
    font-size: 8pt;
    height: 40px;
}

.dashbuttons {
    font-size: 1.2rem;
}

@media (max-width: 1400px) {
    .dashbuttons {
        font-size: .8rem;
    }
}

@media (max-width: 950px) {
    .dashbuttons {
        font-size: 8pt;
        height: 40px;
    }
}

.dashname {
    margin-left: 5px;
}

@media (max-width: 1100px) {
    .dashbuttongroup {
        margin-top: 90px;
        font-size: 0.8rem;
    }

    .dashname {
        visibility: hidden;
    }
}

@media (max-width: 950px) {
    .dashbuttongroup {
        margin-top: 110px;
        font-size: 0.5rem;
    }

    .dashname {
        visibility: hidden;
    }
}

.slidein {
    -moz-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    -webkit-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
}

.center {
    height: 100%;
    width: 100%;
    position: relative;
}

.center p {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.pagefilters {
    margin-top: 5px;
}


.fswidget {
    max-height: 80vh;
    height: 80vh;
}

.fsviewer {
    position: relative;
    left: 80px;
    top: 80px;
    max-width: 70vw;
    width: 70vw;
}

.fullscreenwidget {
    cursor: pointer;
}

.fa-rotate-45 {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#editor {
    align-items: center;
    margin: 0 auto;
}


.mobilehide {
    display: inherit;
}

.mobileshow {
    display: none;
}

@media (max-width: 950px) {

    .mobilehide {
        display: none;
    }

    .mobileshow {
        display: inline;
    }
}

.bigred {
    background-color: #A51111;
    color: #FFFFBC;
    font-family: 'Roboto';
    font-size: 1.1rem;
    line-spacing: 1.0;
    font-weight: 200;
    padding: 0;
    margin: 0;
}

.littlered {
    position: relative;
    top: -6px;
    background-color: #A51111;
    color: #FFFFBC;
    font-family: 'Roboto';
    font-size: .7rem;
    font-weight: 200;
    padding: 3px 3px;
}

.newdealbtn {
    position: fixed;
    right: 15px;
    top: 5px;
    padding-top: 2px;
    background-color: #57A483;
    color: #FFFFFF;
    font-family: 'Roboto';
    font-size: 0.9rem;
    line-spacing: 1.0;
    font-weight: 500;
    height: 25px;
    width: 140px;
    cursor: pointer;
    border-radius: 5px;
}

.newdealbtn:hover {
    background-color: rgba(42, 119, 86, 1);
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    flex-grow: 1;
}


@media (max-width: 950px) {
    .flex-container {
        margin-top: 50px;
    }
}