/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Jan 8, 2019, 9:19:24 AM
    Author     : jta
*/



.sortlist ul{
    list-style-type: none;
    margin: 1Opx;
    padding: 5px;
    width: 95%;
}

.sortlist ol{
    list-style-type: none;
    margin: 1Opx;
    padding: 5px;
    width: 95%;
}

.sortlist li {
   display: inline-block;
   width: 100%;
   float:left;
   border: solid;
   border-width: 1px;
   border-radius: 3px;
   border-color: #DDD;
   margin: 3px;
   padding: 3px;
  }
