/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Apr 4, 2019, 10:23:04 AM
    Author     : jta
*/

.tour {
    background-color: #FFFFDF;
    border-style: solid;
    border-width: 1.5px;
    border-color: grey;
    border-radius: 10px;
    margin-top: 10px;
    padding: 20px;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    /* visibility: hidden;*/
    font-size: small;
    position: relative;
    z-index: 10000;
}

.tour h1{
    font-size: 16px;
    font-weight: bold;
    color: darkblue;
}

.tour h2{
    font-size: 16px;
    font-weight: bold;
    color: darkslateblue;
}

.tour h3{
    font-size: 14px;
    font-weight: bold;
    color: slateblue;
}


.tour button {
    height: 30px;
    font-size: x-small;
    background-color: slateblue;
    color: white;
    padding: 4px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 5px;
    border-radius: 20px;
    min-width: 110px;
}
.tour button.endtour{
    background-color: silver;
    color: black;
}
.tourcontent {
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    padding: 10px;
    font-size: small;
    color: #666;
    max-height: 300px;
    overflow-x: auto;
}

.tour a {
    font-size: small;
    color: mediumblue;
}

div.callout {
    background-color: #FFFFDF;
    border-style: solid;
    border-width: 1.5px;
    border-color: grey;
    border-radius: 10px;
    margin-top: 10px;
    padding: 20px;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 0px 20px #999;
	
}
.callout.tiny{
    width: 15%;
    min-width: 200px;
}
.callout.small{
    width: 20%;
    min-width: 250px;
}
.callout.medium{
    width: 35%;
    min-width: 300px;
}
.callout.large{
    width: 50%;
    min-width: 350px;
}
.callout.x-large{
    width: 75%;
    min-width: 400px;
}

.callout::before {
	content: "";
	width: 0px;
	height: 0px;
	border: 0.8em solid transparent;
	position: absolute;
}

.callout.bottom::before {
	left: 45%;
	bottom: -20px;
	border-top: 10px solid #444;
}
.callout.bottom-left::before {
	left: 9px;
	bottom: -20px;
	border-top: 10px solid #444;
}

.callout.bottom-right::before {
	right: 9px;
	bottom: -20px;
	border-top: 10px solid #444;
}

.callout.top::before {
	left: 45%;
	top: -20px;
	border-bottom: 10px solid #444;
}

.callout.top-right::before {
	right: 9px;
	top: -20px;
	border-bottom: 10px solid #444;
}
.callout.top-left::before {
	left: 9px;
	top: -20px;
	border-bottom: 10px solid #444;
}

.callout.right::before {
	right: -20px;
	top: 40%;
	border-left: 10px solid #444;
}
.callout.right-top::before {
	right: -20px;
	top: -0px;
	border-left: 10px solid #444;
}
.callout.right-bottom::before {
	right: -20px;
	bottom: -0px;
	border-left: 10px solid #444;
}


.callout.left::before {
	left: -20px;
	top: 40%;
	border-right: 10px solid #444;
}

.callout.left-top::before {
	left: -20px;
	top: -0px;
	border-right: 10px solid #444;
}
.callout.left-bottom::before {
	left: -20px;
	bottom: -0px;
	border-right: 10px solid #444;
}


.touricon{
    color: slateblue;
    background-color: ghostwhite;
    border-radius: 50%;
    font-size: 14pt;
    height: 15px;
    width: 15px;
    text-align: center;
    vertical-align: top;
    /*
    margin: 3px;
    border-color: lightgrey;
    border-width: 1px;
    border-style:  solid;
    font-weight: bold;
    line-height: 20px;
    */

}

.touricon-small{
    margin-top: -1px;
    color: slateblue;
    background-color: ghostwhite;
    border-radius: 50%;
    font-size: 14pt;
    height: 10px;
    width: 10px;
    text-align: center;
    vertical-align: top;
    margin-left: 3px;
    margin-right: 5px;
    /*
    margin: 3px;
    border-color: lightgrey;
    border-width: 1px;
    border-style:  solid;
    font-weight: bold;
    line-height: 20px;
    */

}

