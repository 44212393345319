.toast {
    position: absolute;
    top: 120px;
    right: 50px;
    background-color: #ffface;
    z-index: 10000;
}

.toast-success {
    background-color: greenyellow;
    color: #444444;
}

.toast-error{
    background-color: red;
    color: white;
    font-weight: bold;
}
.toast-warning{
    background-color: orange;
    color: white;
    font-weight: bold;
}