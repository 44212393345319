.wizardtext {
    font-size: 1.0rem;
}

.tstyle {
    height: 20px;
    width: 20px;
}

.navbtns {
    margin-top: 20px;
    display: block;
    width: 100%;
}

.recommnedation {
    font-size: 0.9rem;
    font-weight: 100;
    font-style: italic;
    color: slateblue;
}

.mytron {
    background-color: lightskyblue;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
    align-items: center;
    vertical-align: middle;
}

.tabicon {
    height: 75px;
    width: 75px;
    border-radius: 50%;
    font-size: small;
    font-weight: 500;
}

.timeline {
    background: linear-gradient(180deg,
    rgba(0, 0, 0, 0) calc(50% - 3px),
    rgba(192, 192, 192, 1) calc(50%),
    rgba(0, 0, 0, 0) calc(50% + 3px)
    );
    margin-bottom: 30px;
}

.activetab {
    background-color: dodgerblue;
    color: white;
}

.completedtab {
    background-color: lightskyblue;
    color: lightslategrey;
}

.futuretab {
    background-color: lightgrey;
    color: deepskyblue;
}
