.mobilepanel {
    background-color: ghostwhite;
    border-style: solid;
    border-width: thin;
    border-radius: 8px;
    border-color: lightslategrey;
    box-shadow: 4px 4px lightslategrey;
    height: 80vh;
    padding: 5px;
    margin: 8px;
    overflow-y: auto;
    max-width: 600px;
}

.mobilepanel_btn {
    /* margin: auto; */

    padding: 0px;
    font-size: xx-small;
    height: 17px;
    width: 20px;
    margin-left: 5px;
}

.mobilepanel-title {
    font-weight: 500;
    font-size: 10pt;
    color: darkslategrey;
    display: block;
    background-color: lightgrey;
    border-style: solid;
    border-width: thin;
    border-color: lightslategrey;
    border-radius: 10px 0px 10px 0px;
    padding: 5px ;
    margin: 10px;
    white-space: nowrap;
    cursor: pointer;
}

.mobiledealcard {
    margin: 10px;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    border-color: slategrey;
    /* max-height: 15vh; */
    min-height: 130px;
    overflow-y: auto;
    overflow-x: hidden;
}

.mobilepanel-dealtitle{
    text-align: left;
    font-weight: bold;
    background-color: aliceblue;
    font-size: 12pt;
    padding: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

}
.completedtask {
    background-color: silver;
}


.mobiledealcard-content{
    position: relative;
    max-height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 95%;
}
.mobiledealcard-name {
    position: relative;
    top: 0;
    left: 0;

    padding: 5px;
    text-align: left;
    border-bottom-style: solid;
    border-bottom-width: thin;
    border-bottom-color: darkslateblue;
    font-size: small;
}
.mobiledealcard-toolbar {
    display: block;
    width: 100%;
    font-size: small;
    border-color: lightslategrey;
    border-width: 1px;
    border-bottom-style: outset;

}
.mobiledealcard-task {
    clear: both;
    display: block;
    width: 100%;
    margin: 5px;
    font-size: small;
    vertical-align: middle;
}
.mobiledealcard-task-item {
    width: 98%;
    display: block;
    margin-top: 5px;
    border-top-style: solid;
    border-top-width: thin;
    border-top-color: cornflowerblue;
}
.mobileeditor{
    position: fixed;
    width: 50vh;
    max-width: 80vh;
    top: 10%;
    right: 5px;
    z-index: 10;
}

.mobilecard-info {
    font-size: small;
    color: cornflowerblue;
}

.floatingeditor{
    /* more mobile friendly than the edit region used on reg pages */
    position: fixed;
    width: 50vh;
    max-width: 85%;
    max-height: 75vh;
    top: 15vh;
    overflow-y: auto;
    right: 10px;
    z-index: 10;
}

@media (max-width: 1100px) {
    .floatingeditor {
        width: 60vw;
    }
}

@media (max-width: 800px) {
    .floatingeditor {
        width: 85vw;
    }
}

.mobilepanel-dealtitle .n-widgettools {
    top: -5px;
    margin-top: 0px;
    padding-top: 0px;
}

.mobiledealcard-name .n-widgettools {
    top: -5px;
    margin-top: 0px;
    padding-top: 0px;
}

.mobiledashboard .sortbar {
    visibility: hidden;
}

.mobiledashboard .n-widget {
    max-height: 70vh;
}

.mobiledashboard .n-widget-content {
    max-height: 63vh;
    overflow-y: auto;
}