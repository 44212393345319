
.academynav {
    text-align: center;
    vertical-align: center;
    cursor: pointer;
}

.embededplayer {
    width: 800px;
    height: 450px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

    @media (max-width: 1100px) {
        .embededplayer {
            width: 600px;
            height: 337px;
        }
    }

    @media (max-width: 800px) {
        .embededplayer {
            width: 400px;
            height: 225px;
        }
    }

.videoicon {

    font-size: 40pt;
    font-weight: bold;
    color: #0594a9;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}


.iframe-container {
    /* overflow: hidden; */
    padding-top: 56.25%;
    position: relative;
}

.iframe-container iframe {
    border: 0;
    /*margin-left: 5%; */
    margin: auto;
    top: 5%;
    left: 10%;
    height: 80%;
    position: absolute;
    width: 80%;
}

@media (max-width: 1100px){
    .iframe-container iframe{
        height: 80%;
        width: 80%;
        margin-left: 10%;
        top: 30px;
    }
}


.questionbox {
    background-color: #cfd3d6;;
    text-align: center;
    padding: 16px;
    border-radius: 5px;
    color: #1d012f;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    flex-grow: 1;
}

.resultbox {
    height: 63vh;
    border-radius: 5px;
    min-width: 25vw;
    /* overflow-y: auto; */
}

.resultlist {
    height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
}

.flex-container > div {
    background-color: white;
    width: 25vw;
    min-width: 300px;
    margin: 10px;
    padding: 20px;

}

.resultcard {
    border-style: solid;
    border-width: 1px;
    border-color: lightslategrey;
    font-size: .8rem;
    color: #1d012f;
    padding: 3px;
    margin: 3px;
}

.resultcard-name {
    font-style: bold;
}

.resultcard :hover {
    cursor: pointer;
}

.resultcard-content {
    color: darkslategrey;
    font-size: .7rem;
}

.academysrchbtn {
    background-color: ghostwhite;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    padding: 5px;
}


.oa-widget {
    width: 85%;
    text-align: center;
    align-items: center;
    align-content: center;
    margin: auto;
    padding: 0;
}

.oa-widget .n-widget {
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    overflow-x: hidden;
}

.oa-widget .widget {
    top: 5%;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    overflow-x: hidden;
}

.oa-widget .oa-small {
    margin: auto;
    width: 70%;
}

