
.editor-container1 {
    width: 100%;
    height: 100%;
    min-height: 35vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow-x: hidden;
    overflow-y: auto;
}

.quill-editor {
    height: 20vh;
    width: 100%;
    align-items: stretch;
    overflow-x: hidden;
    overflow-y: auto;
}
