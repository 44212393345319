.selector {
    max-width: 500px;
}
.selecteditem, .selecteditem{
    font-size: small;
    background-color: lightskyblue;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: darkblue;
    box-shadow: 2px 2px lightgrey;
    color: #333;
    text-align: center;
    margin-left: 5px;
    margin-right: 7px;
    padding: 2px 5px 2px 5px;
    display:inline-block;
    vertical-align:middle;
}

.wf {
    background-color: limegreen;
}

.selecteditem-remove {
    padding: 0px;
    margin: 5px;
    display: inline-block;
    background-color: lightgrey;
    vertical-align:middle;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    border-radius: 4px;
    width: 25px;
}
.selecteditem-remove::after{
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
    content: '  \f2ed';
}


.selecteditem-edit {
    padding: 3px;
    margin: 5px;
    display: inline-block;
    background-color: lightgrey;
    vertical-align:middle;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    border-radius: 4px;
    width: 25px;
}
.selecteditem-edit::after{
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
    content: '  \f044';
}

.timefilterselector {
    display: inline;
}