/* css for widgets */

.widget {
    background-color: ghostwhite;
    /* margin: 5px; */
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    border-color: antiquewhite;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    display: inline-block;
    min-width: 200px;
    font-family: 'Roboto', sans-serif;
    width: 90%;
    /* resize: both; */
    /* overflow-y: auto;*/
}

.n-widget {
    background-color: #FFFFFF;
    border-radius: var(--widgetradius);
    -webkit-border-radius: var(--widgetradius);
    -moz-border-radius: var(--widgetradius);
    width: 97%;

    border-style: solid;
    border-width: 1px;
    border-color: antiquewhite;

    font-family: 'Roboto', sans-serif;
    margin: 10px;
    max-height: 50vh;
    overflow-y: hidden;
    overflow-x: hidden;


}

@media (max-width: 800) {
    .n-widget {
        margin: 5px;
        left: 10px;
        width: 100%;
    }


}

.n-widget-title {
    display: block;
    text-align: left;
    /* border-radius: 5px; */

    border-top-left-radius: var(--widgetradius);
    -webkit-border-top-left-radius: var(--widgetradius);
    -moz-border-radius-topleft: var(--widgetradius);
    border-top-right-radius: var(--widgetradius);
    -webkit-border-top-right-radius: var(--widgetradius);
    -moz-border-radius-topright: var(--widgetradius);

    border-bottom-style: hidden;
    height: 32px;
    background-color: var(--wdghead-color);
    opacity: 1;
    color: var(--wdgtitle-color);
    font-family: 'Roboto', sans-serif;
    font-size: 1.0rem;
    font-weight: 300;
    top: 0px;
    left: 0px;
    padding: 0px 5px 0px 10px;
    overflow-x: hidden;
}
    @media (max-width: 1400px) {
        .n-widget-title {
            font-size: 1.0rem;
        }
    }
    @media (max-width: 1000px) {
        .n-widget-title {
            font-size: .8rem;
        }
    }

.n-widgettools {
    display: inline;
    background-color: var(--wtoolback-color);
    color: var(--wtool-color);
    float: right;
    /* padding: 0px; */
    margin: auto;
    text-align: center;
    font-size: 0.7rem;

    border-radius: var(--widgetradius);
    top: 0px;
    right: 0px;
    padding: 5px var(--widgetradius) 0px var(--widgetradius);
    height: 100%;

}

@media (max-width: 1400px) {
    .n-widgettools {
        font-size: 0.7rem;
    }
}

@media (max-width: 800px) {
    .n-widgettools {
        font-size: 0.6rem;
    }
}

.n-widgettools i {
    cursor: pointer;
}

.n-widgettools i:hover {
    color: var(--wtool-color);
}

.n-widgettools .selectedfilter {
    background-color: green;
    color: ghostwhite;
    border-radius: 50%;
}

.n-widget-content {
    width: 98%;
    background-color: #FFFFFF;
    color: darkslategray;
    padding: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 300;
    top: 32px;
    overflow-y: auto;
    overflow-x: inherit;
    max-height: 90%;
    height: 90%;
}


.n-widget-medium {
    height: 35vh;
    overflow-y: hidden;
    overflow-x: hidden;
}

.n-widget-medium .n-widget-content {
    max-height: 85%;
}
.n-widget-small {
    height: 25vh;
    overflow-y: hidden;
    overflow-x: hidden;
}

.n-widget-small .n-widget-content {
    max-height: 80%;
}

.n-widget-xsmall {
    height: 130px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.n-widget-xsmall .n-widget-content {
    max-height: 78%;
}

.n-widgettools i {
    padding: 3px;
}
.rightButton {
    position: relative;
    z-index: 99;
    float: right;
}

.calendar .n-widget {
    height: 85vh;
    max-height: 85vh;
}

.popedit-content input {
    margin: 5px;
    padding: 5px;
    width: 90%;
}

.popedit-content textarea {
    margin: 5px;
    padding: 5px;
}

.n-widgettools .ouido-done {
    font-size: 0.9rem;
    background-color: green;
    color: ghostwhite;
    border-radius: 50%;
}


.loginform .n-widget .n-widget-content {
    overflow-x: hidden;
    overflow-y: hidden;
}

.popeditform .popeditsavebtn {
    margin-top: 10px;
}

.popeditform .popeditcancel {
    margin-top: 10px;
}

.scrollable {
    overflow-y: scroll;
}

.stretch {
    max-height: 100%;
}