
.msgwidget {
    top: 65px;
    right: 10px;
    position: fixed;
    width: 30%;
    background-color: ghostwhite;
    text-align: left;
}
/* sizing for small screens */
@media (max-width: 800px) {
    .msgwidget {
        width: 75%;
    }
}

.msgrow {
    text-align: left;
    margin: 10px;
    padding: 10px;
    background-color: aliceblue;
    border-style: solid;
    border-width: 1px;
    border-color: lightgrey;
    border-radius: 8px;
    width: 95%;

}

.msgsevere {
    background-color: red;
    color: white;
}

.msgwarning {
    background-color: #FFFACE;
    color: darkslategrey;
}

.msggeneral{
    background-color: aliceblue;
    color: darkslategrey;
}