
// the ~ allows you to reference things in node_modules
@import url("https://fonts.googleapis.com/css?family=Open Sans|Roboto");
@import "~bootstrap/scss/bootstrap";
@import "~datatables.net-bs4/css/dataTables.bootstrap4.min.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~@fortawesome/fontawesome-free/css/v4-shims.min.css";



@import "../css/jtwebtour.css";
@import "../css/icons.css";
@import "../css/selector.css";
@import "../css/sidebar.css";
@import "../css/sortable.css";
@import "../css/tags.css";
@import "../css/toast.css";
@import "../css/widget.css";
@import "../css/notifications.css";
@import "../css/knowledgebase.css";
@import "../css/academy.css";
@import "../css/topnav.css";
@import "../css/dealboard.css";
@import "~tui-calendar/dist/tui-calendar.min.css";
@import "../css/mobileDB.css";
@import "../css/pulltabs.css";
@import "../css/loader.css";
@import "../css/ouidocards.css";
@import "../css/wizard.css";
@import "../css/print.css";
@import "../css/odash.css";

@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";

@import "../css/ouido-quill.css";

@import "../css/app.css";
@import "../css/broker.css";

// @import "../css/ouidopalette.scss";


:root {
  bg-color: #1d012f;
  sb-color: #4f1738;
  sbicon-color: #e0e0e0;
  pe-color: #2a2b2c;
  mn-color: #cfd3d6;
  btn-color: #8f989f;
  btn-text: #e0e0e0;
  wdghead-color: #e0e0e0;
  wdgtitle-color: #1d012f;
  wtoolback-color: #c12c12;
  wtool-color: #1d012f;
  hdtext-color: #1d012f;
  sortbar-color: #8f989f;
  sorted-color: #1d012f;
  sortedtext-color: #bdc3c7;
  overdue-color: #ce234c;
  overduetext-color: #ffface;
  today-color: #00ff7f;
  todaytext-color: #666666;
  widgetradius: 5px;
}

/*
$mynewcolor: #8f989f;
//$mynewcolor: #4F1738;

.btn-secondary {
  @include button-variant($mynewcolor, darken($mynewcolor, 7.5%), darken($mynewcolor, 10%), lighten($mynewcolor,5%), lighten($mynewcolor, 10%), darken($mynewcolor,20%));
}

.btn-outline-secondary {
  @include button-outline-variant($mynewcolor, darken($mynewcolor,4%), lighten($mynewcolor,5%), $mynewcolor);
}
*/

//@import "bootstrap/dist/css/bootstrap.css";

