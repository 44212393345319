/*!
 * TOAST UI Calendar
 * @version 1.12.13 | Tue Apr 28 2020
 * @author NHN FE Development Lab <dl_javascript@nhn.com>
 * @license MIT
 */
.tui-full-calendar-layout {
  height: 100%;
  position: relative;
  box-sizing: border-box;
}
.tui-full-calendar-layout * {
  box-sizing: border-box;
}
.tui-full-calendar-dragging {
  cursor: move;
}
.tui-full-calendar-resizing {
  cursor: row-resize;
}
.tui-full-calendar-resizing-x {
  cursor: col-resize;
}
.tui-full-calendar-hidden {
  display: none !important;
}
.tui-full-calendar-invisible span {
  visibility: hidden;
}
.tui-full-calendar-clear {
  zoom: 1;
}
.tui-full-calendar-clear:after {
  content: '';
  display: block;
  clear: both;
}
.tui-full-calendar-scroll-y {
  overflow-y: scroll;
}
.tui-full-calendar-dot {
  display: inline-block;
  position: relative;
  top: -1px;
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
.tui-full-calendar-holiday {
  color: #f00;
}
.tui-full-calendar-today {
  background: rgba(218,229,249,0.3);
}
.handle-x {
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAECAMAAACEE47CAAAACVBMVEX///////////+OSuX+AAAAA3RSTlMrQJG5H4EIAAAAEUlEQVR4AWNgYoRABhjEFAEAArQAIcHQcPsAAAAASUVORK5CYII=);
}
.handle-y {
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAICAMAAADp7a43AAAACVBMVEX///////////+OSuX+AAAAA3RSTlMrQJG5H4EIAAAAEUlEQVR4AWNgYmRiZABB/CwAAtgAIUTUNkMAAAAASUVORK5CYII=);
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-devicepixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) {
  .handle-x {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAICAMAAADHqI+lAAAACVBMVEX///////////+OSuX+AAAAA3RSTlMZK5EY+QKaAAAAGUlEQVR4AWNgYmJAwegCIMDIiIwxBKhhBgAcSABh8gN42gAAAABJRU5ErkJggg==);
    background-size: 8px 4px;
  }
  .handle-y {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAQCAMAAAAcVM5PAAAACVBMVEX///////////+OSuX+AAAAA3RSTlMEK5EMBzK5AAAAGElEQVR4AWNgYmIAYxgDBBgZQRjOoKcaABzQAGGjsIM/AAAAAElFTkSuQmCC);
    background-size: 4px 8px;
  }
}
.tui-full-calendar-month-week-item .tui-full-calendar-weekday-grid {
  overflow-y: hidden;
}
.tui-full-calendar-month-week-item .tui-full-calendar-weekday-schedules {
  overflow-y: visible;
  height: 0;
}
.tui-full-calendar-month-week-item .tui-full-calendar-weekday-schedule {
  margin: 0 10px;
}
.tui-full-calendar-month-week-item .tui-full-calendar-today {
  background: none;
}
.tui-full-calendar-month-week-item .tui-full-calendar-today .tui-full-calendar-weekday-grid-date-decorator {
  display: inline-block;
  width: 27px;
  height: 27px;
  line-height: 27px;
  text-align: center;
  background: #135de6;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  margin-left: 2px;
}
.tui-full-calendar-weekday-container,
.tui-full-calendar-weekday-grid,
.tui-full-calendar-weekday-grid-line {
  height: 100%;
  min-height: inherit;
}
.tui-full-calendar-weekday-grid {
  position: absolute;
  width: 100%;
  overflow-y: scroll;
}
.tui-full-calendar-weekday-border {
  border-top: 1px solid #ddd;
}
.tui-full-calendar-weekday-container {
  position: relative;
}
.tui-full-calendar-weekday-container>div {
  height: 100%;
}
.tui-full-calendar-weekday-grid-line {
  position: absolute;
  padding: 3px;
}
.tui-full-calendar-weekday-grid-line .tui-full-calendar-weekday-grid-footer {
  position: absolute;
  bottom: 4px;
}
.tui-full-calendar-weekday-grid-line .tui-full-calendar-weekday-grid-date {
  display: inline-block;
  width: 27px;
  height: 27px;
  line-height: 27px;
  text-align: center;
}
.tui-full-calendar-weekday-grid-line .tui-full-calendar-weekday-grid-date-title {
  line-height: 27px;
  margin-right: 5px;
}
.tui-full-calendar-weekday-grid-line .tui-full-calendar-weekday-grid-more-schedules {
  float: right;
  display: inline-block;
  height: 27px;
  line-height: 27px;
  padding: 0 5px;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  color: #aaa;
}
.tui-full-calendar-weekday-creation {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: scroll;
}
.tui-full-calendar-weekday-schedules {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  font-size: 12px;
  overflow-y: scroll;
}
.tui-full-calendar-weekday-schedules-height-span {
  width: 1px;
  margin-left: -1px;
}
.tui-full-calendar-weekday-schedule-block {
  position: absolute;
}
.tui-full-calendar-weekday-schedule-block-dragging-dim {
  opacity: 0.3;
}
.tui-full-calendar-weekday-schedule {
  position: relative;
  margin: 0 10px 0 1px;
  cursor: pointer;
  border-left-style: solid;
  border-left-width: 3px;
}
.tui-full-calendar-weekday-schedule.tui-full-calendar-weekday-schedule-time {
  border-left-width: 0;
}
.tui-full-calendar-weekday-schedule.tui-full-calendar-weekday-schedule-time .tui-full-calendar-weekday-schedule-title {
  padding-left: 9px;
}
.tui-full-calendar-weekday-schedule-bullet {
  position: absolute;
  padding: 0;
  width: 6px;
  height: 6px;
  top: 6px;
  left: 0;
  border-radius: 50%;
}
.tui-full-calendar-weekday-schedule-bullet-focused {
  left: 10px;
  background: #fff;
}
.tui-full-calendar-weekday-schedule-title {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 3px;
  font-weight: bold;
}
.tui-full-calendar-weekday-schedule-title-focused {
  padding-left: 16px;
}
.tui-full-calendar-weekday-schedule-cover {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.2);
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.1);
}
.tui-full-calendar-weekday-exceed-left .tui-full-calendar-weekday-schedule {
  margin-left: 0;
  border-left-width: 0;
}
.tui-full-calendar-weekday-exceed-right .tui-full-calendar-weekday-schedule {
  margin-right: 0;
}
.tui-full-calendar-weekday-exceed-right .tui-full-calendar-weekday-resize-handle {
  display: none;
}
.tui-full-calendar-weekday-exceed-in-month {
  cursor: pointer;
}
.tui-full-calendar-weekday-exceed-in-month:hover {
  background-color: #f0f1f5;
}
.tui-full-calendar-weekday-exceed-in-week,
.tui-full-calendar-weekday-collapse-btn {
  position: absolute;
  bottom: 5px;
  margin-right: 5px;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  padding: 1px 5px;
  background-color: #fff;
  border: 1px solid #ddd;
  color: #000;
}
.tui-full-calendar-weekday-resize-handle {
  position: absolute;
  top: 0;
  right: 0;
  width: 6px;
  background-position: 3px center;
  cursor: col-resize;
  line-height: 18px;
}
.tui-full-calendar-weekday-filled {
  background-color: #e8e8e8 !important;
}
.tui-full-calendar-left {
  height: 100%;
  float: left;
  box-sizing: border-box;
  display: table;
}
.tui-full-calendar-left-content {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
  font-size: 11px;
}
.tui-full-calendar-right {
  height: 100%;
  overflow-y: scroll;
  position: relative;
}
.tui-full-calendar-week-container {
  width: 100%;
  height: inherit;
  display: inline-block;
  font-size: 10px;
  min-height: 600px;
}
.tui-full-calendar-week-container .tui-full-calendar-today {
  background: none;
}
.tui-full-calendar-dayname {
  position: absolute;
  margin-left: -1px;
  height: 100%;
  overflow: hidden;
}
.tui-full-calendar-dayname.tui-full-calendar-today {
  font-weight: bold;
}
.tui-full-calendar-dayname-container {
  overflow-y: scroll;
}
.tui-full-calendar-dayname-leftmargin {
  position: relative;
  height: 100%;
}
.tui-full-calendar-dayname-date {
  font-size: 26px;
}
.tui-full-calendar-dayname-name {
  font-weight: bold;
  font-size: 12px;
}
.tui-full-calendar-daygrid-layout {
  height: 100%;
}
.tui-full-calendar-daygrid-layout .tui-full-calendar-right {
  overflow-y: hidden;
}
.tui-full-calendar-daygrid-guide-creation-block {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.tui-full-calendar-timegrid-container {
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
}
.tui-full-calendar-timegrid-container-split {
  height: 100%;
  position: relative;
  overflow: hidden;
}
.tui-full-calendar-timegrid-left {
  position: absolute;
}
.tui-full-calendar-timegrid-hour {
  position: relative;
  color: #555;
  box-sizing: border-box;
}
.tui-full-calendar-timegrid-hour:first-child span {
  display: none;
}
.tui-full-calendar-timegrid-hour:last-child {
  border-bottom: none;
}
.tui-full-calendar-timegrid-hour span {
  position: absolute;
  top: -11px;
  left: 0;
  right: 5px;
  text-align: right;
  line-height: 25px;
}
.tui-full-calendar-timegrid-right {
  position: relative;
}
.tui-full-calendar-timegrid-gridline {
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
}
.tui-full-calendar-timegrid-gridline:last-child {
  border-bottom: none;
}
.tui-full-calendar-timegrid-schedules {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.tui-full-calendar-timegrid-hourmarker {
  position: absolute;
  width: 100%;
  display: table;
}
.tui-full-calendar-timegrid-hourmarker-line-left {
  position: absolute;
  min-height: 1px;
  left: 0;
}
.tui-full-calendar-timegrid-hourmarker-line-today {
  position: absolute;
  min-height: 1px;
}
.tui-full-calendar-timegrid-hourmarker-line-right {
  position: absolute;
  min-height: 1px;
  right: 0;
}
.tui-full-calendar-timegrid-hourmarker-time {
  padding-right: 5px;
  line-height: 12px;
  text-align: right;
  display: table-cell;
  vertical-align: bottom;
}
.tui-full-calendar-timegrid-todaymarker {
  position: absolute;
  text-indent: -9999px;
  width: 9px;
  height: 9px;
  background-color: #135de6;
  margin: -4px 0 0 -5px;
  border-radius: 50%;
}
.tui-full-calendar-timegrid-sticky-container {
  position: absolute;
  top: 0;
}
.tui-full-calendar-timegrid-timezone-label-container {
  position: absolute;
}
.tui-full-calendar-timegrid-timezone-label-cell {
  display: table;
}
.tui-full-calendar-timegrid-timezone-label {
  display: table-cell;
  vertical-align: middle;
  padding-right: 5px;
  text-align: right;
}
.tui-full-calendar-timegrid-timezone-close-btn {
  cursor: pointer;
  position: absolute;
  text-align: center;
  background-color: #fff;
}
.tui-full-calendar-timegrid-timezone-close-btn .tui-full-calendar-icon {
  width: 5px;
  height: 10px;
}
.tui-full-calendar-time-date {
  position: absolute;
  height: 100%;
  margin-left: -1px;
  box-sizing: content-box;
}
.tui-full-calendar-time-date:last-child {
  border-right: none;
  margin: 0;
}
.tui-full-calendar-time-date:last-child .tui-full-calendar-time-schedule,
.tui-full-calendar-time-date:last-child .tui-full-calendar-time-guide-creation {
  left: 0px;
}
.tui-full-calendar-time-date-schedule-block-wrap {
  position: relative;
  height: 100%;
}
.tui-full-calendar-time-date-schedule-block {
  position: absolute;
  right: 0px;
}
.tui-full-calendar-time-date-schedule-block-pending {
  opacity: 0.7;
}
.tui-full-calendar-time-date-schedule-block-dragging-dim {
  opacity: 0.3;
}
.tui-full-calendar-time-date-schedule-block-focused {
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.2);
}
.tui-full-calendar-time-date-schedule-block-cover {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.2);
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.1);
}
.tui-full-calendar-time-schedule {
  position: relative;
  left: 1px;
  height: 100%;
  overflow: hidden;
  font-size: 12px;
  font-weight: bold;
}
.tui-full-calendar-time-schedule-content {
  overflow: hidden;
  border-left-width: 3px;
  border-left-style: solid;
  padding: 1px 0 0 3px;
}
.tui-full-calendar-time-schedule-content-travel-time {
  font-weight: normal;
  font-size: 11px;
}
.tui-full-calendar-time-resize-handle {
  position: absolute;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 5px;
  text-align: center;
  color: #fff;
  cursor: row-resize;
  background-position: center top;
}
.tui-full-calendar-time-guide-creation {
  position: absolute;
  right: 10px;
  left: 1px;
  padding: 3px;
}
.tui-full-calendar-time-guide-move .tui-full-calendar-time-schedule,
.tui-full-calendar-time-guide-resize .tui-full-calendar-time-schedule,
.tui-full-calendar-time-guide-move .tui-full-calendar-time-resize-handle,
.tui-full-calendar-time-guide-resize .tui-full-calendar-time-resize-handle {
  opacity: 0.8;
  z-index: 97;
}
.tui-full-calendar-time-guide-creation-label {
  cursor: default;
}
.tui-full-calendar-time-guide-bottom {
  position: absolute;
  bottom: 3px;
}
.tui-full-calendar-month {
  height: 100%;
  min-height: 600px;
}
.tui-full-calendar-month-dayname {
  width: 100%;
  position: absolute;
  font-size: 13px;
}
.tui-full-calendar-month-dayname-item {
  height: 100%;
  font-weight: bold;
}
.tui-full-calendar-month-week-item {
  position: relative;
}
.tui-full-calendar-month-week-item>div {
  height: 100%;
}
.tui-full-calendar-month-more {
  height: inherit;
  min-width: 280px;
  min-height: 150px;
}
.tui-full-calendar-month-more-title {
  position: relative;
}
.tui-full-calendar-month-more-title-day {
  font-size: 23px;
  color: #333;
}
.tui-full-calendar-month-more-title-day-label {
  font-size: 12px;
  color: #333;
}
.tui-full-calendar-month-more-close {
  position: absolute;
  right: 0;
  outline: 0;
  background: none;
  border: 0;
  font-size: 14px;
  line-height: 28px;
  padding: 0 7px;
  cursor: pointer;
}
.tui-full-calendar-month-more-list {
  overflow-y: auto;
}
.tui-full-calendar-month-more-schedule {
  cursor: pointer;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
}
.tui-full-calendar-month-guide-block {
  position: absolute;
}
.tui-full-calendar-month-weekday-schedule {
  margin-top: 2px;
}
.tui-full-calendar-month-creation-guide {
  top: 0;
  bottom: -1px;
  left: -1px;
  right: 0;
  position: absolute;
  z-index: 20;
}
.tui-full-calendar-month-guide-focused {
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.2);
}
.tui-full-calendar-month-guide {
  position: relative;
  padding-left: 3px;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tui-full-calendar-month-guide-cover {
  width: 100%;
  position: absolute;
  top: -50%;
  left: -50%;
  background-color: rgba(0,0,0,0.2);
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.1);
}
.tui-full-calendar-month-exceed-left .tui-full-calendar-month-guide {
  margin-left: 0px;
}
.tui-full-calendar-month-exceed-right .tui-full-calendar-month-guide {
  margin-right: 0px;
}
.tui-full-calendar-month-exceed-right .tui-full-calendar-month-guide-handle {
  display: none;
}
.tui-full-calendar-month-guide-handle {
  position: absolute;
  top: 0;
  right: 3px;
  width: 6px;
  background-position: 3px center;
  cursor: col-resize;
}
.tui-full-calendar-vlayout-container {
  position: relative;
}
.tui-full-calendar-splitter {
  clear: left;
  cursor: row-resize;
}
.tui-full-calendar-splitter:hover {
  border-color: #999;
}
.tui-full-calendar-splitter-focused {
  background-color: #ddd;
  border: none;
}
.tui-full-calendar-splitter-guide {
  position: absolute;
  width: 100%;
  height: 3px;
  border: none;
  background-color: #e8e8e8;
}
.tui-full-calendar-popup {
  position: absolute;
  font-weight: 2.5;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.1);
  clear: both;
}
.tui-full-calendar-popup-container {
  min-width: 474px;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.1);
  background-color: #fff;
  border: solid 1px #d5d5d5;
  padding: 17px;
  border-radius: 2px;
}
.tui-full-calendar-popup-section {
  font-size: 0;
  min-height: 40px;
}
.tui-full-calendar-section-calendar {
  width: 176px;
}
.tui-full-calendar-section-calendar.tui-full-calendar-hide {
  height: 21px;
  visibility: hidden;
}
.tui-full-calendar-section-title {
  width: calc(100% - 36px);
  padding-right: 4px;
}
.tui-full-calendar-section-title input {
  width: 365px;
}
.tui-full-calendar-button.tui-full-calendar-section-private {
  height: 32px;
  padding: 8px;
  font-size: 0;
  margin-left: 4px;
}
.tui-full-calendar-section-private.tui-full-calendar-public .tui-full-calendar-ic-private {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAKdJREFUKBVjYCATMKLri46Olvn9+3fX////HUByjIyMB1hZWcuWLl36BFktikaQpl+/fl0EKhBCVgRkv2NjY9NH1syErABkE1TTdqBCWRAG8reDxKBycOUoGmHOA2pIA5kOwiA2SDVMDq4TmREaGvofhJHFcLHhfgwLC9sKNNULl0KQODCgtq1atcobxIY7lZAmkGJkNXCNIAlSwIjSCApqIgJnK0wNALoOPwSpOcq0AAAAAElFTkSuQmCC) no-repeat;
}
.tui-full-calendar-section-start-date,
.tui-full-calendar-section-end-date {
  width: 176px;
}
.tui-full-calendar-section-start-date input,
.tui-full-calendar-section-end-date input {
  width: 139px;
}
.tui-full-calendar-section-start-date:hover .tui-full-calendar-popup-section-item,
.tui-full-calendar-section-end-date:hover .tui-full-calendar-popup-section-item,
.tui-full-calendar-section-start-date:focus .tui-full-calendar-popup-section-item,
.tui-full-calendar-section-end-date:focus .tui-full-calendar-popup-section-item,
.tui-full-calendar-section-start-date:hover .tui-datepicker,
.tui-full-calendar-section-end-date:hover .tui-datepicker,
.tui-full-calendar-section-start-date:focus .tui-datepicker,
.tui-full-calendar-section-end-date:focus .tui-datepicker {
  border-color: #bbb;
}
.tui-full-calendar-popup-section-item:focus {
  border-color: #bbb;
}
.tui-full-calendar-section-date-dash {
  font-size: 12px;
  color: #d5d5d5;
  height: 32px;
  padding: 0 4px;
  vertical-align: middle;
}
.tui-full-calendar-popup-section-item.tui-full-calendar-section-allday {
  border: none;
  padding: 0 0 0 8px;
  cursor: pointer;
}
.tui-full-calendar-popup-section-item.tui-full-calendar-section-location {
  display: block;
}
.tui-full-calendar-popup-section-item.tui-full-calendar-section-location input {
  width: 400px;
}
.tui-full-calendar-section-allday .tui-full-calendar-icon.tui-full-calendar-ic-checkbox {
  margin: 0;
}
.tui-full-calendar-popup-section-item.tui-full-calendar-section-allday .tui-full-calendar-content {
  padding-left: 4px;
}
.tui-full-calendar-section-state {
  width: 109px;
}
.tui-full-calendar-section-state .tui-full-calendar-content {
  width: 58px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tui-full-calendar-popup-section-item {
  height: 32px;
  padding: 0 9px 0 12px;
  border: 1px solid #d5d5d5;
  display: inline-block;
  font-size: 0;
  border-radius: 2px;
}
.tui-full-calendar-popup-section-item:hover {
  border-color: #bbb;
}
.tui-full-calendar-popup-section-item:focus {
  border-color: #bbb;
}
.tui-full-calendar-popup-section-item .tui-full-calendar-icon {
  position: relative;
}
.tui-full-calendar-icon.tui-full-calendar-ic-title {
  top: 2px;
}
.tui-full-calendar-popup-section-item .tui-full-calendar-content {
  text-align: left;
  display: inline-block;
  font-size: 12px;
  vertical-align: middle;
  position: relative;
  padding-left: 8px;
}
.tui-full-calendar-section-calendar .tui-full-calendar-dropdown-button .tui-full-calendar-content {
  width: 125px;
  text-overflow: ellipsis;
  overflow: hidden;
  top: -1px;
}
.tui-full-calendar-popup-section-item input {
  border: none;
  height: 30px;
  outline: none;
  display: inline-block;
}
.tui-full-calendar-popup-section-item input::placeholder {
  color: #bbb;
  font-weight: 300;
}
.tui-full-calendar-dropdown {
  position: relative;
}
.tui-full-calendar-dropdown:hover .tui-full-calendar-dropdown-button {
  border: 1px solid #bbb;
}
.tui-full-calendar-dropdown-button.tui-full-calendar-popup-section-item {
  height: 32px;
  font-size: 0;
  top: -1px;
}
.tui-full-calendar-dropdown-arrow {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAHlJREFUKBVjYBgFOEOAEVkmPDxc89+/f6eAYjzI4kD2FyYmJrOVK1deh4kzwRggGiQBVJCELAZig8SQNYHEmEEEMrh69eo1HR0dfqCYJUickZGxf9WqVf3IakBsFBthklpaWmVA9mEQhrJhUoTp0NBQCRAmrHL4qgAAuu4cWZOZIGsAAAAASUVORK5CYII=) no-repeat;
}
.dropdown.open .tui-full-calendar-dropdown-arrow,
.tui-full-calendar-open .tui-full-calendar-dropdown-arrow {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAIFJREFUKBVjYBj+gBmXF2NiYiTV1dV5rl279gWbGiZsgg0NDSw/f/5cCZRbBWJjU4PVRjExsR6g4nAgln/z5g3v1atXd6JrxtAYHh4e+v///z4khZa6urrXgJqvIYkxMCJzgJo0//37dwooxoMsDmR/YWJiMlu5cuV1NPFRLrYQAADMVCaUtbG7XwAAAABJRU5ErkJggg==) no-repeat;
}
.tui-full-calendar-dropdown-menu {
  position: absolute;
  top: 31px;
  padding: 4px 0;
  background-color: #fff;
  border: 1px solid #d5d5d5;
  border-top: none;
  border-radius: 0 0 2px 2px;
  width: 100%;
}
.tui-full-calendar-dropdown:hover .tui-full-calendar-dropdown-menu {
  border: 1px solid #bbb;
  border-top: none;
}
.tui-full-calendar-dropdown-menu {
  display: none;
}
.tui-full-calendar-open .tui-full-calendar-dropdown-menu {
  display: block;
}
.tui-full-calendar-dropdown-menu-item {
  height: 30px;
  border: none;
  cursor: pointer;
}
.tui-full-calendar-section-calendar .tui-full-calendar-dropdown-menu-item {
  width: 100%;
}
.tui-full-calendar-section-state .tui-full-calendar-dropdown-menu-item {
  width: 100%;
}
.tui-full-calendar-dropdown-menu-item:hover {
  background-color: rgba(81,92,230,0.05);
}
.tui-full-calendar-dropdown-menu-item .tui-full-calendar-content {
  line-height: 30px;
}
.tui-full-calendar-button.tui-full-calendar-popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  padding: 2px;
  border: none;
}
.tui-full-calendar-section-button-save {
  height: 36px;
}
.tui-full-calendar-popup-save {
  float: right;
}
.tui-full-calendar-popup-arrow-border,
.tui-full-calendar-popup-arrow-fill {
  position: absolute;
}
.tui-full-calendar-arrow-top .tui-full-calendar-popup-arrow-border {
  border-top: none;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #d5d5d5;
  border-left: 8px solid transparent;
  left: calc(50% - 8px);
  top: -7px;
}
.tui-full-calendar-arrow-right .tui-full-calendar-popup-arrow-border {
  border-top: 8px solid transparent;
  border-right: none;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #d5d5d5;
  top: calc(50% - 8px);
  right: -7px;
}
.tui-full-calendar-arrow-bottom .tui-full-calendar-popup-arrow-border {
  border-top: 8px solid #d5d5d5;
  border-right: 8px solid transparent;
  border-bottom: none;
  border-left: 8px solid transparent;
  left: calc(50% - 8px);
  bottom: -7px;
}
.tui-full-calendar-arrow-left .tui-full-calendar-popup-arrow-border {
  border-top: 8px solid transparent;
  border-right: 8px solid #d5d5d5;
  border-bottom: 8px solid transparent;
  border-left: none;
  top: calc(50% - 8px);
  left: -7px;
}
.tui-full-calendar-arrow-top .tui-full-calendar-popup-arrow-fill {
  border-top: none;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  left: -7px;
  top: 1px;
}
.tui-full-calendar-arrow-right .tui-full-calendar-popup-arrow-fill {
  border-top: 7px solid transparent;
  border-right: none;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #fff;
  top: -7px;
  right: 1px;
}
.tui-full-calendar-arrow-bottom .tui-full-calendar-popup-arrow-fill {
  border-top: 7px solid #fff;
  border-right: 7px solid transparent;
  border-bottom: none;
  border-left: 7px solid transparent;
  left: -7px;
  bottom: 1px;
}
.tui-full-calendar-arrow-left .tui-full-calendar-popup-arrow-fill {
  border-top: 7px solid transparent;
  border-right: 7px solid #fff;
  border-bottom: 7px solid transparent;
  border-left: none;
  top: -7px;
  left: 1px;
}
.tui-full-calendar-button {
  background: #fff;
  border: 1px solid #d5d5d5;
  border-radius: 2px;
  text-align: center;
  outline: none;
  font-size: 12px;
  cursor: pointer;
  color: #333;
}
.tui-full-calendar-button:hover {
  border-color: #bbb;
  color: #333;
}
.tui-full-calendar-button:active {
  background: #f9f9f9;
  color: #333;
}
.tui-full-calendar-button .round {
  border-radius: 25px;
}
.tui-full-calendar-confirm {
  width: 96px;
  height: 36px;
  border-radius: 40px;
  background-color: #ff6618;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  border: none;
}
.tui-full-calendar-confirm:hover {
  background-color: #e55b15;
  color: #fff;
}
.tui-full-calendar-confirm:active {
  background-color: #d95614;
  color: #fff;
}
.tui-full-calendar-icon.tui-full-calendar-right {
  float: right;
  top: 1px;
}
.tui-full-calendar-icon .tui-full-calendar-none {
  display: none;
}
.tui-full-calendar-icon.tui-full-calendar-calendar-dot {
  border-radius: 8px;
  width: 12px;
  height: 12px;
  margin: 1px;
}
input[type='checkbox'].tui-full-calendar-checkbox-square {
  display: none;
}
input[type='checkbox'].tui-full-calendar-checkbox-square + span {
  display: inline-block;
  cursor: pointer;
  line-height: 14px;
  margin-right: 8px;
  width: 14px;
  height: 14px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAADpJREFUKBVjPHfu3O5///65MJAAmJiY9jCcOXPmP6kApIeJBItQlI5qRAkOVM5o4KCGBwqPkcxEvhsAbzRE+Jhb9IwAAAAASUVORK5CYII=) no-repeat;
  vertical-align: middle;
}
input[type='checkbox'].tui-full-calendar-checkbox-square:checked + span {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAMBJREFUKBWVkjEOwjAMRe2WgZW7IIHEDdhghhuwcQ42rlJugAQS54Cxa5cq1QM5TUpByZfS2j9+dlJVt/tX5ZxbS4ZU9VLkQvSHKTIGRaVJYFmKrBbTCJxE2UgCdDzMZDkHrOV6b95V0US6UmgKodujEZbJg0B0ZgEModO5lrY1TMQf1TpyJGBEjD+E2NPN7ukIUDiF/BfEXgRiGEw8NgkffYGYwCi808fpn/6OvfUfsDr/Vc1IfRf8sKnFVqeiVQfDu0tf/nWH9gAAAABJRU5ErkJggg==) no-repeat;
}
input[type='checkbox'].tui-full-calendar-checkbox-round {
  display: none;
}
input[type='checkbox'].tui-full-calendar-checkbox-round + span {
  display: inline-block;
  cursor: pointer;
  width: 14px;
  height: 14px;
  line-height: 14px;
  vertical-align: middle;
  margin-right: 8px;
  border-radius: 8px;
  border: solid 2px;
  background: transparent;
}
.tui-full-calendar-popup-top-line {
  position: absolute;
  border-radius: 2px 2px 0 0;
  width: 100%;
  height: 4px;
  border: none;
  top: 0;
}
.tui-full-calendar-popup-detail .tui-full-calendar-popup-container {
  width: 301px;
  min-width: 301px;
  padding-bottom: 0;
}
.tui-full-calendar-popup-detail .tui-full-calendar-icon {
  width: 12px;
  height: 12px;
  background-size: 12px;
  position: relative;
}
.tui-full-calendar-popup-detail .tui-full-calendar-icon {
  margin-right: 8px;
}
.tui-full-calendar-popup-detail .tui-full-calendar-icon.tui-full-calendar-ic-location-b {
  top: -2px;
}
.tui-full-calendar-popup-detail .tui-full-calendar-icon.tui-full-calendar-ic-user-b {
  top: -2px;
}
.tui-full-calendar-popup-detail .tui-full-calendar-icon.tui-full-calendar-ic-state-b {
  top: -1px;
}
.tui-full-calendar-popup-detail .tui-full-calendar-icon.tui-full-calendar-calendar-dot {
  width: 10px;
  height: 10px;
  margin-right: 8px;
  top: -1px;
}
.tui-full-calendar-popup-detail .tui-full-calendar-content {
  line-height: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 2;
}
.tui-full-calendar-popup-detail .tui-full-calendar-section-header {
  margin-bottom: 6px;
}
.tui-full-calendar-popup-detail .tui-full-calendar-popup-detail-item-separate {
  margin-top: 4px;
}
.tui-full-calendar-popup-detail .tui-full-calendar-popup-detail-item-indent {
  text-indent: -20px;
  padding-left: 20px;
}
.tui-full-calendar-popup-detail .tui-full-calendar-schedule-title {
  font-size: 15px;
  font-weight: bold;
  line-height: 1.6;
  word-break: break-all;
}
.tui-full-calendar-popup-detail .tui-full-calendar-schedule-private {
  display: none;
  width: 16px;
  height: 16px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAKpJREFUKBVjYCATMKLri46Olvn9+3fX////HUByjIyMB1hZWcuWLl36BFktikaQpl+/fl0EKhBCVgRkv2NjY9NH1syErABkE1TTdqBCWRAG8reDxKBycOUoGmHOA2pIA5kOwiA2SDVMDqYTRSNQUBIkgewkJDZYDqYR7sewsLCtQFO9YBLYaGBAbVu1apU3SA5uIyFNIMXIauAaQRKkgBGlERTURATOVpgaABRQQOK46wEAAAAAAElFTkSuQmCC) no-repeat 16px;
}
.tui-full-calendar-popup-detail .tui-full-calendar-schedule-private .tui-full-calendar-ic-private {
  display: block;
}
.tui-full-calendar-popup-detail .tui-full-calendar-section-detail {
  margin-bottom: 16px;
}
.tui-full-calendar-popup-detail .tui-full-calendar-section-button {
  border-top: 1px solid #e5e5e5;
  font-size: 0;
}
.tui-full-calendar-section-button .tui-full-calendar-icon {
  margin-right: 4px;
  top: -3px;
}
.tui-full-calendar-section-button .tui-full-calendar-content {
  position: relative;
  top: 2px;
}
.tui-full-calendar-popup-edit,
.tui-full-calendar-popup-delete {
  display: inline-block;
  padding: 7px 9px 11px 9px;
  width: calc(50% - 1px);
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
}
.tui-full-calendar-popup-vertical-line {
  background: #e5e5e5;
  width: 1px;
  height: 14px;
  vertical-align: middle;
  display: inline-block;
  margin-top: -7px;
}
.tui-datepicker {
  left: -12px;
  z-index: 1;
  border-color: #d5d5d5;
}
.tui-full-calendar-icon {
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
}
.tui-full-calendar-icon.tui-full-calendar-ic-title {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAO5JREFUKBVjYCATMOLSFxkZqfHnz5+1QHktNDVbV69e7cOCJgjmQjXtB3IksMh7g8SY0CXQNTEyMlYD1fBCabhyFI3omkCq/v//PwnotC8gGq4LyIBrxKYJpBBoU15oaCgPiEbWCPYjUEIFGBBY/QS0qRWooRVIg/UBDXgMYoBtBHJSgWxsAQFWjET8BBqQBuLDNM4Can6GpAAb8ydQMADo3x0gSbDGlStX3gVqdMSjGUUTSCNKAggPD1cDOmU/EEuBJKEAQxNIHEUjSABNM1ZNIHXMIAIZXL169a2+vv5moK18QKeXAv20B1meYjYAr7xrljpOzc0AAAAASUVORK5CYII=) no-repeat;
}
.tui-full-calendar-icon.tui-full-calendar-ic-location {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAR1JREFUKBWdUTtPg1AUBiT8CydHJtv/0MTJRWAgcTK1bq0/pO3k4E4IYLo2Me46tS4wumjSpV07kAb6HXLPzaGPRZLL+c73uE/D+OdnHuaCIOhVVTUEf620pWVZ0yRJ3qW3FfR9f1zX9UgaGJumOUnT9Fn3DDzPuwPOuD9TvSzL3kizhOFJ4LnjOJc0wM0FP2Asgx0mEehHUfRHgzDzqF3GOogzbJg8V6XHFqYv4Cvqy7J8DcOwWYmw8Hwy1kHMRjcaKuEGgV82caWbZay3indagJyxcKLOlKeRdJA627YfUVaN0v6tlKbZVjCO4zW2cw91px3AxJEmOONCNoTzPP9xXZfOd6u0Bzz60RGOgmQuiuIb4S3gB0IvaoJW2QMDs1bBoH1CAQAAAABJRU5ErkJggg==) no-repeat;
}
.tui-full-calendar-icon.tui-full-calendar-ic-date {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAGpJREFUKBVjYKAGCA0N/Q/C6GZhE2cEKQoLC9v6//9/L3QN2PiMjIzbVq1a5c0EkiRWE7JasEZsJhMSI1sjC7LJq1evBvsZWQyZjRxwZNs4hDSiBA6y55EDBRsb7EdQasAmiUNsKw5x4oQBkUAeDPJ53KsAAAAASUVORK5CYII=) no-repeat;
}
.tui-full-calendar-icon.tui-full-calendar-ic-state {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAIxJREFUKBVjYCATMKLrCw8P9/z3798soLgMVO4JExNT2sqVK7cjq2VC5oDYME2MjIyNIAwUkoGKoShlQeFBOGCbVq1a1QDihoaG1gMpmO0gITAAOzUsLGzr////vWCC+GigK7YBDfUGO5VYTSADYWox/IjPNmS5UY3IoYHGBgcOKG7QxPFxt+KTJCgHAGcZJbGLRuJ2AAAAAElFTkSuQmCC) no-repeat;
}
.tui-full-calendar-icon.tui-full-calendar-ic-private {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAKpJREFUKBVjYCATMKLri46Olvn9+3fX////HUByjIyMB1hZWcuWLl36BFktikaQpl+/fl0EKhBCVgRkv2NjY9NH1syErABkE1TTdqBCWRAG8reDxKBycOUoGmHOA2pIA5kOwiA2SDVMDqYTRSNQUBIkgewkJDZYDqYR7sewsLCtQFO9YBLYaGBAbVu1apU3SA5uIyFNIMXIauAaQRKkgBGlERTURATOVpgaABRQQOK46wEAAAAAAElFTkSuQmCC) no-repeat;
}
.tui-full-calendar-icon.tui-full-calendar-ic-public {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAKdJREFUKBVjYCATMKLri46Olvn9+3fX////HUByjIyMB1hZWcuWLl36BFktikaQpl+/fl0EKhBCVgRkv2NjY9NH1syErABkE1TTdqBCWRAG8reDxKBycOUoGmHOA2pIA5kOwiA2SDVMDq4TmREaGvofhJHFcLHhfgwLC9sKNNULl0KQODCgtq1atcobxIY7lZAmkGJkNXCNIAlSwIjSCApqIgJnK0wNALoOPwSpOcq0AAAAAElFTkSuQmCC) no-repeat;
}
.tui-full-calendar-icon.tui-full-calendar-ic-close {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAJRJREFUKBXFkNERhCAMREUbuEKohzq0Eq2DDq6Da4B60KezDORkxj+ZwchmX0IYhtdWCGFl9y5g82NtzDnPdzAaudo76ZBS+nrvPxiInMkJcs5tMcZFfqcfxdqIRiELof+BiIJPg+mExmpmvKRn3zKj7OrG9Y79szPL14A1xEP0Hgy4gBZS5R7czHj3ehSgOzkVeyfuGrBw/WLm0hsAAAAASUVORK5CYII=) no-repeat;
}
.tui-full-calendar-ic-location-b {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAOZJREFUKBWVUT0KwjAUbkzGTuJWPYNDpV0cXD2ETuIRBK+gs4ubp3BwcXBp6eINBKWDgydoid9X8oKCggYeee/7S9IGwZ9LverTNO3Wdb2y1o6IK6WOWutFlmU30XmDE58hbgvpTA+Y+mJqCemS20jdG2N6LPYMICc6b5BrIG3ONBZ7CoVj7w0cfllGRDj+gKQpjt/iPU0ye/LkROcNANaoCUzjqqquIsBuHddAWoiyLO9RFHUwJ4JxR/qmKIqdYG9vCMNwCeIiJHuHecj/B0GSJBng7ifO+ErDPM8L4b7ucRzPWJ8ET1E7YC7tmi9qAAAAAElFTkSuQmCC);
}
.tui-full-calendar-ic-state-b {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAHlJREFUKBVjYCARMCKrNzEx8QTyZ/3//18GJM7IyPgESKWdOXNmO4gPAkwQCk6CFQMVNoIwVOMsuCw6w9jY+D8Iw8TR+SBxsJOATtkKNM0LphAbDbRxG9Bp3mAnEVIMMgCmBt0P2AxHERusGkAhgOJQ7Jyt2IUJiAIAwwIn24FgmhkAAAAASUVORK5CYII=);
}
.tui-full-calendar-icon.tui-full-calendar-ic-user-b {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAJpJREFUKBVjYKA1YES3wMTExBMoNgsqnnbmzJntyGqYkDlQ9qz////LgDCQD9MIV4ZNA1wSGwObhjRGRsYnIAzUkIZNE0licE+bm5tr/fnzJx1osjPQBFmoKY+BftnLwsIy8+TJk9dAYmANxsbGoUD2YiBmBwliAT+BYrFnz55dDfNDO1AAl2KQfpAcSA0DTIMyiEMAEKMG0wgAolIjcM7Tjm8AAAAASUVORK5CYII=);
}
.tui-full-calendar-icon.tui-full-calendar-ic-edit {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAMdJREFUKBVjYCARMOJTb2xsLMfIyBjLysq64Pjx409BapnwaQDKzf7//3/L79+/D1tbW0uB1LJg02BmZqYIVPgdKBf/79+//UC2xs+fP8OB/H4MG0CK//79ewCkEGQYExOTI5DawMnJuQTER/EDTDFQXA4kCQQ3QBpOnz79AsJF8gMWxTA1KDTYBhyKMUwH6WSysrKSB7kZyIY5AySOVTFIggno+5VAmijFYA1AwhzEgAKcJsMUwIMVGKPH2NnZ7ZFDBKYImQYAuO5YIMgk39gAAAAASUVORK5CYII=);
}
.tui-full-calendar-icon.tui-full-calendar-ic-delete {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAFhJREFUKBVjYCARMKKrNzEx2fr//38vkDgjI+O2M2fOeCOrAWtAVoQsicyGaWZCFsTHBtr6H588Tjm4H4yNjfGacPbsWbBaop0Es3JYaQBFDMxjWOitMDEA3EEZfFEISwUAAAAASUVORK5CYII=);
}
.tui-full-calendar-icon.tui-full-calendar-ic-arrow-solid-top {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAIFJREFUKBVjYBj+gBmXF2NiYiTV1dV5rl279gWbGiZsgg0NDSw/f/5cCZRbBWJjU4PVRjExsR6g4nAgln/z5g3v1atXd6JrxtAYHh4e+v///z4khZa6urrXgJqvIYkxMCJzgJo0//37dwooxoMsDmR/YWJiMlu5cuV1NPFRLrYQAADMVCaUtbG7XwAAAABJRU5ErkJggg==) no-repeat;
}
.tui-full-calendar-icon.tui-full-calendar-ic-milestone {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAGFJREFUKBVjYCARMILU/3dw+I+hj5FxG+P+/d7o4rg1IKtE0syELI6T/f+/F0yOOA0w1UCa9hpYkGxjYDxwABwIILH/jo5bGWBuZ2TcClOHogEmCKKxBSlInPZ+ANlCEgAA37EU4AHbBQUAAAAASUVORK5CYII=) no-repeat;
}
.tui-full-calendar-icon.tui-full-calendar-ic-arrow-left {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAHCAYAAAAvZezQAAAAAXNSR0IArs4c6QAAAFZJREFUCB1jZICCyspK83///hUxgvhVVVV6f//+3c3ExJTMVFNTowqU2cHMzJzf3t6+hen379/zgIp2t7W1rQCpZmJlZU0C0q5AbREgAQwzwAIgGZgtADMCJqH/QyvhAAAAAElFTkSuQmCC) no-repeat;
}
.tui-full-calendar-icon.tui-full-calendar-ic-arrow-right {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAHCAYAAAAvZezQAAAAAXNSR0IArs4c6QAAAFxJREFUCB1jKC8vX1lZWWnOAAVMQLD4379/m6qqqvRAYowgAsiJAAr2sbCw2IMFQIIVFRUL////r8SCpMKVlZXVnhFooA9Q+VxmZmbXtra2S0xATizQYD8QB6QaAJMLJ9BqE9yTAAAAAElFTkSuQmCC) no-repeat;
}
.tui-full-calendar-ic-repeat-b {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAQpJREFUKBVjYCARMCKrNzU1tf/3718lUMwQiP8yMjKeYWJi6j516tRhExOThjNnzjTANQAFqv///98CVHQPSO8A0ixATa5AtjyQPgDETmfPngULMpiZmbn//fu3BSg4B6ggCyjxG8hm8PT0ZH/9+vUJoJgTiA8CTCACqDgXaOJ9Xl7eTJhikDhQcSVQsQGITT8A9rSxsfF/mJVApzWCQgPGd3BwYPny5cstoNOuAZ3rAwoJOAAqviAqKtoOEwAaxPr58+dpQL4iEGeAxJFt2AfkOwA1PQTSu4Em/gGyPYC0EpCuAdraCtIADiWgQCPQOmdmZmYHoNgVoCJfIB0CpG8DI84BphgoRjoAAAzgdELI91E5AAAAAElFTkSuQmCC);
}
/*# sourceMappingURL=src/css/main.css.map */
