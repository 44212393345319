.pulltab {
    padding: 3px 10px 3px 10px;
    margin: 5px;
    background-color: red;
    color: white;
    text-align: center;
}

.pulltab-title {
    font-weight: bold;
    font-size: small;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: grey;
    text-align: center;
    width: 100%;
}

.pulltab-content {
    font-weight: bold;
    font-size: 20pt;
    color: greenyellow;
    text-align: center;
    width: 100%;
}