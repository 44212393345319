
.article{
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    background-color: white;
    font-size: 12pt;
    padding: 10px;
    margin: 5px;
}

.article-comment{
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    background-color: ghostwhite;
    font-size: 10pt;
    padding: 10px;
    margin: 5px;
    font-size: 10pt;
    width: 100%;
    float: left;
}

.article-comment-by {
    font-style: italic;
    font-size: small;
    float: right;
}