.n-panel {

    background-color: ghostwhite;
    border-radius: var(--widgetradius);
    -webkit-border-radius: var(--widgetradius);
    -moz-border-radius: var(--widgetradius);

    border-style: solid;
    border-width: 1px;
    border-color: antiquewhite;

    font-family: 'Poppins', sans-serif;
    margin: 15px;
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;

    min-width: 300px;
    max-width: 300px;


}

.panel_btn {
    /* margin: auto; */

    padding: 0px;
    font-size: xx-small;
    height: 17px;
    width: 20px;
    margin-left: 5px;
}

.stat  {
    font-size: small; color: cornflowerblue
}
.statnumber {
    color: red;
    font-weight: bolder;
}
.target {
    background-color: #FFFACE;
}

@media (max-width: 1100px) {
    .panel {
        height: 50vh;
        max-height: 50vh;
    }
}
@media (max-width: 800px) {
    .panel {
        height: 30vh;
        max-height: 30vh;
    }
}

.n-panel-content {
    width: 98%;
    background-color: #FFFFFF;
    color: darkslategray;
    padding: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 300;
    top: 32px;
    overflow-y: auto;
    overflow-x: inherit;
    max-height: 90%;
    height: 90%;

    /*
    background-color: #FFFFFF;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 300;
    */
}




.n-panel-title {

    display: block;
    text-align: left;
    /* border-radius: 5px; */

    border-top-left-radius: var(--widgetradius);
    -webkit-border-top-left-radius: var(--widgetradius);
    -moz-border-radius-topleft: var(--widgetradius);
    border-top-right-radius: var(--widgetradius);
    -webkit-border-top-right-radius: var(--widgetradius);
    -moz-border-radius-topright: var(--widgetradius);

    border-bottom-style: hidden;
    height: 32px;
    background-color: var(--wdghead-color);
    opacity: 1;
    color: var(--wdgtitle-color);
    font-family: 'Roboto', sans-serif;
    font-size: 1.1rem;
    font-weight: 300;
    top: 0px;
    left: 0px;
    padding: 0px 5px 0px 10px;
    overflow-x: hidden;
    text-align: left;
    /*
        display: block;
        border-top-left-radius: 3px;
        -webkit-border-top-left-radius: 3px;
        -moz-border-radius-topleft: 3px;
        border-top-right-radius: 3px;
        -webkit-border-top-right-radius: 3px;
        -moz-border-radius-topright: 3px;
        border-bottom-style: inset;
        height: 32px;
        background-color: #e5e5e5;
        opacity: 1;
        color: #440047;
        font-family: 'Poppins', sans-serif;
        top: 0px;
        left: 0px;
        width: 100%;

        padding: 0px 5px 0px 10px;
        margin-top: 0px;
        font-weight: 500;
        font-size: .8rem;
        /*
                    padding: 5px ;
                    margin: 10px;
        */
    white-space: nowrap;
    cursor: pointer;
}

.n-panel-title .n-widgettools {
    /* margin-top: 0px; */
}

.collapsed .n-widgettools {
    visibility: hidden;
}

.panel-card {
    background-color: aliceblue;
    border-style: solid;
    border-width: thin;
    border-radius: 5px;
}
/* rotated*/
.rotated{
    transform: rotate(90deg);
    transform-origin: center;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    padding-left: 20px;
    overflow-x: hidden;
    overflow-y: hidden;
}

.collapsed {
    min-width: 30px;
    width: 30px;
    text-align: center;
    overflow-x: hidden;
    overflow-y: hidden;
}


.collapsed .n-widgettools {
    /* transform: rotate(90deg); */
    visibility: hidden;

}


.dropform .n-widget {
    overflow: hidden;
}

.dropform .n-widget-content {
    overflow: hidden;
}


.r-panel {
    transform: rotate(90deg);
    transform-origin: left;
    background-color: lightgrey;
    color: #1d012f;
    width: 50vh;
    height: 60px;

    position: relative;
    top: -27px;
    padding-left: 5px;
    left: 0px;
    text-align: left;
    max-height: 70vh;
    cursor: pointer;
}

.minipanel {
    width: 35px;
    min-width: 35px;
    max-width: 35px;
    overflow-x: hidden;
    overflow-y: hidden;
    max-height: 70vh;
    height: 70vh;
}
