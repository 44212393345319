.tag {
    border-style: solid;
    border-width: 1px;
    border-color: slateblue;
    border-radius: 5px;
    background-color: ghostwhite;
    color: darkslateblue;
    margin: 3px;
    padding: 5px;
    display: inline-block;
}
.tag:hover{
    background-color:antiquewhite;
}
.addtag:after{
    font-family: "FontAwesome";
    content: '\f067';
}
.removetag:after{
    font-family: "FontAwesome";
    content: '\f014';
}