.card-margin {
    margin-bottom: 0.75rem;
}

body {
    background: #F4F7FD;
    margin-top: 20px;
}

.btn-ouido {
    margin-right: 10px;
}

.ouido-primary {
    background-color: #57A483 !important;
    color: white !important;
}

.ouido-secondary {
    background-color: #02568C !important;
    color: white !important;;
}

.taskrow {
    border-bottom-color: #d5d5d5;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding-top: 10px;
}

.taskdetails {
    padding-left: 2rem;
    position: relative;
    /* top: -1rem; */
    float: left;
    clear: both;
}

.task-footer {
    text-align: center;
    background-color: #f7f7f7;
    border-style: solid;
    border-width: 1px;
    border-color: #dfdfdf;
    font-size: small;
}

.targetlink {
    cursor: pointer;
}

.cornblue {
    color: #02568C !important;
    text-decoration: underline;
    cursor: pointer;

}

.w-45 {
    width: 45vw !important;
    min-width: 45vw !important;
    max-width: 45vw !important;
}

.w-20 {
    width: 19.7vw !important;
    min-width: 19.7vw !important;
    max-width: 19.7vw !important;
}

.w-30 {
    width: 30vw !important;
    min-width: 30vw !important;
    max-width: 40vw !important;
}

.w-35 {
    width: 35vw !important;
    min-width: 35vw !important;
    max-width: 35vw !important;
}

.w-90 {
    width: 90.5vw !important;
    min-width: 90.5vw !important;
    max-width: 90.5vw !important;
}

@media (max-width: 950px) {
    .w-45, .w-20, .w-30, .w-35, .w-90 {
        width: 100vw !important;
        min-width: 100vw !important;
        max-width: 100vw !important;
    }
}

.menuopts {
    position: absolute;
    color: var(--wdgtitle-color);
    right: 5px;
    text-align: right;
}

.card-header .menuopts {
    position: absolute;
    color: var(--wdgtitle-color);
    right: 5px;
    text-align: right;
}

.card-header .menuopts button {
    color: var(--wdgtitle-color);
}

.card-icon {
    position: absolute;
    right: 5px;
    text-align: right;
}

.stretch-card {
    flex-grow: 1;
}

.card-header .menuopts {
    /* position: absolute;*/
    right: 2px;
    text-align: right;
}

.menuopts i {
    margin-left: -3px;
    margin-right: 5px;

}

.menuopts .dropdown-toggle:after {
    border: none !important;
}


.card {
    border: 0;
    box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
    -ms-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border: 1px solid #e6e4e9;
    border-radius: 8px;
}

.card .card-header.no-border {
    border: 0;
}


.card .card-header {
    background: var(--wdghead-color);
    color: var(--wdgtitle-color);
    padding: 0 0.5375rem;
    font-weight: 400;
    font-size: 1.08rem;
    display: flex;
    align-items: center;
    min-height: 50px;
}

.card-header:first-child {
    border-radius: calc(8px - 1px) calc(8px - 1px) 0 0;
}


.card-body {
    font-size: .8rem;
}

.nav .nav-item .nav-link {
    color: #02568C;
}


.nav-link .todaytasks a {
    color: black !important;
}