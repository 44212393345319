@media print {
    .n-widget {
        width: 95%;
        height: auto;
    }

    .n-widget-content {
        height: auto;
        width: 95%;
    }

    .ouido-chart {
        height: auto;
        width: 95%;
    }

    .dealcard {
        margin: 3px;
        border-style: solid;
        border-width: 1px;
        border-radius: 3px;
        border-color: silver;
        min-height: 5vh;
        /*max-height: 20vh;*/
        overflow-y: auto;
        overflow-x: hidden;
        width: 95%;
    }


    .taskcard {
        margin: 3px;
        padding: 5px;
        border-style: solid;
        border-width: 1px;
        border-radius: 3px;
        border-color: silver;
        min-height: 5vh;
        /*max-height: 20vh;*/
        overflow-y: auto;
        overflow-x: hidden;
        width: 95%;
    }

    .taskcard-content {

        max-height: 100%;
        overflow-y: inherit;
        overflow-x: hidden;
    }

    .taskcard-name {
        display: inline;
        position: relative;
        top: 0;
        left: 0;

        padding: 0px 10px 10px 10px;
        text-align: left;

        border-bottom-style: none;
        border-bottom-width: thin;
        border-bottom-color: linen;

        font-size: 0.9rem;
        color: #8e678e;
    }

    .taskcard-toolbar {
        display: none;
    }

    .tbar-vertical {
        display: none;
    }

    .tasklabel {
        display: block;
        margin-right: 5px;
        font-size: 0.8rem;
        color: #4A235A;
    }

    .tasklabel .inline {
        display: inline;
    }

    .taskfield {
        display: block;
        margin: 5px;
        font-size: 0.9rem;
        color: black;
    }

    .taskfield .inline {
        display: inline;
    }

    .tasktags {
        margin-left: 30px;
    }

}